import React, { useState, useEffect } from "react";
import DataGrid, { Column, Editing, Paging, Lookup, RequiredRule } from "devextreme-react/data-grid";

import { getGridProps } from '../../../../helpers/DataGrid/DataGridColumn.helper';
import { getEventEditorPreparing } from "components/ContractInFocus/Logs/Helpers/LogsGrid.helper";
import { NewLogsProps } from "../Logs";
import LogsWrapper from "components/ContractInFocus/Logs/LogsWrapper";
import { technicalEventsLogsColumns, technicalEventsLogsColumnWidths, portfolioTechnicalEventsLogsColumnWidths } from "../Models/logsColumns.model";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";

const getContractEndpoint = (ref?: string | number) => `contracts/${ref}/technical-event-logs/?format=json`;
const getPortfolioEndpoint = (id?: string | number) => `portfolios/${id}/technical-event-logs/?format=json`;

const TechnicalEventLogs: React.FC<NewLogsProps> = (
    {
        dataSource,
        metadata,
        setContentReady,
        handleEditingStart,
        handleRowUpdated,
        handleOnRowPrepared,
        getColumnPropsExt,
        contract,
        portfolio,
        styles
    }) => {
    const columnWidths = portfolio ? portfolioTechnicalEventsLogsColumnWidths : technicalEventsLogsColumnWidths;
    return (

        <div>
            {dataSource && (
                <DataGrid
                    className="no-print"
                    dataSource={dataSource}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={getEventEditorPreparing(metadata)} //{editableTextAreaOnPreparing(metadata.activeMeta)}
                    onEditingStart={handleEditingStart}
                    onRowUpdated={handleRowUpdated}
                    onRowPrepared={handleOnRowPrepared}
                    onContentReady={() => setContentReady(true)}
                    dateSerializationFormat="yyyy-MM-dd"
                    style={styles}
                >
                    <Paging enabled={false} />
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {portfolio && <Column
                        {...getColumnPropsExt('contract')}
                        //@ts-ignore
                        width={columnWidths['contract']}
                        caption="Contract"
                    ></Column>}
                    <Column
                        {...getColumnPropsExt('date')}
                        width={columnWidths['date']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('details')}
                        width={columnWidths['details']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('system')}
                        width={columnWidths['system']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('plant_name')}
                        width={columnWidths['plant_name']}
                    >
                        <RequiredRule />
                    </Column>
                    {/* <Column {...getColumnPropsExt('resolved_on', { min: "2020/04/12", dateOutOfRangeMessage: "hey this a'int right" })} width={110} /> */}
                    <Column {...getColumnPropsExt('resolved_on', { max: Date.now(), dateOutOfRangeMessage: "resolved on cannot be in the future or before the date raised" })}
                        width={columnWidths['resolved_on']}
                    />
                    <Column {...getColumnPropsExt('resolution')}
                        width={columnWidths['resolution']}
                    />

                </DataGrid>
            )}
        </div>

    );
};

const getWrappedTechnicalEventLogs = (contract: any, portfolio: any, sectionNumberMap: Dictionary<number>) => {
    return () => {
        let getEndpoint: any;
        if (contract) {
            getEndpoint = getContractEndpoint;
        } else if (portfolio) {
            getEndpoint = getPortfolioEndpoint;
        }
        return <LogsWrapper
            WrappedLogsGrid={TechnicalEventLogs}
            getEndpoint={getEndpoint}
            contract={contract}
            portfolio={portfolio}
            logColumnsDef={technicalEventsLogsColumns}
            logsHandle='TechnicalEventLogs'
            logsTitle={reportSubtitles.TechnicalEvents}
            sectionNumberMap={sectionNumberMap}
        />
    }
}

export default getWrappedTechnicalEventLogs;
