import React, { useState, useCallback } from 'react';

// Own
import RightVisibilityMenu from 'components/Common/Components/RightVisibilityMenu/RightVisibilityMenu';
import RightContextButton from 'components/Common/Components/RightVisibilityMenu/Components/RightContextButton';
import { PDFGeneration, DownloadRequestRemote } from 'components/ContractInFocus/HoC/WithPageContext/Types/Download.types';
import { useDispatch, useSelector } from 'react-redux';
import { downloadRequestRemote } from 'components/ContractInFocus/HoC/WithPageContext/Actions/Download.actions';
import { downloadSelector, projectConfigSelector } from 'components/ContractInFocus/HoC/WithPageContext/Selectors/Download.selectors';
import { MainInFocusVisibilitySettings } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces';
import { togglePersonalInFocusSettings } from 'components/ContractInFocus/Actions/contractVisibilitySettings.actions';
import { RightVisibilityMenuT } from 'components/ContractInFocus/interfaces/personalContractSettings.interfaces';
import * as selectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import * as visbilitySelectors from 'components/ContractInFocus/Selectors/visibility.selectors';
import { useEnablePersonalVisibilitySettings } from 'components/Profile/Helpers/profileHelpers';
import { simpleFetchContract, simpleFetchMMRAppendices } from "components/AdminPanel/Contracts/Services/contractService";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { simpleFetchDistribution } from "components/AdminPanel/Contracts/Services/contractService";
import { forkJoin } from 'rxjs';
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { changeSetting } from "store/actions/actions";
import { reportVisibilityModalOpenSelector } from "store/selectors/settings.selector";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";

export interface WithPageContextProps {
  forceHidePersonalVisibilitySettingsMenu?: boolean;
  visibilitySettings: MainInFocusVisibilitySettings;
  className?: string;
  animate?: boolean;
  contract: SiteContract;//ContractInterface;
  portfolio?: HydratedPortfolio;
  frozenFor: string;
  contractReportContext?: boolean;
  passedInPeriod?: Period; // in the contract/portfolio context this will usually be retreived from redux as selectedPeriod rather than passed in, but in other contexts it may be passed in here
  refreshAt?: number;
  pageSize?: number;
  [idx: string]: any;
}

export interface OptionalContractPageContextProps extends Omit<WithPageContextProps, 'contract'> {
  contract?: SiteContract;
  showContractsColumn?: boolean;
}

export interface HASScheduleProps extends OptionalContractPageContextProps {
  defaultExceptionsOnly?: boolean;
}

interface WithPageContextConfig {
  visibilityMenu?: (settings: GetRightVisibilityMenu) => RightVisibilityMenuT;
  customVisibilityMenu?: boolean;
  showRefreshButton?: boolean;
}

// NB Note that on the ContractReport page, this will be called many times - once for each 'subpage' contained
const withPageContext = (ReportComponent: any, configurations?: WithPageContextConfig) => {
  const WithPageContext: React.FunctionComponent<WithPageContextProps> = ({
    contract,
    className,
    forceHidePersonalVisibilitySettingsMenu = false,
    animate = true,
    visibilitySettings,
    contractReportContext,
    frozenFor,
    portfolio,
    ...props
  }) => {
    const selectedDownloadSelector = useSelector(downloadSelector);
    const selectedPersonalContractSettings = useSelector(visbilitySelectors.personalInFocusVisibilitySettingsSelector); // should be passed in
    const selectedVisibilitySettings = useSelector(visbilitySelectors.mainClientVisibilitySettingsObjectsSelector); // should be passed in
    const selectFrozenFor = useSelector(selectors.contractInFocusFrozenForSelector); // should be passed in
    const enablePersonalVisibilitySettings = useEnablePersonalVisibilitySettings();
    const projectConfig = useSelector(projectConfigSelector);
    const portfolioOrContract = portfolio || contract;
    const reportVisibilityModalOpen = useSelector(reportVisibilityModalOpenSelector);

    const dispatch = useDispatch();

    const defaultToggleVisibilityControls = (updateKey: string): void => {
      //default because if a custom function is provided in the visibility settings, it will run that instead (VisibilityControl takes care of that)
      portfolioOrContract && dispatch(togglePersonalInFocusSettings(updateKey, portfolioOrContract)); // should be passed in
    }

    const isDownloadRequested = (): boolean => selectedDownloadSelector.status === PDFGeneration.Requested;

    const isDownloadReady = (): boolean => selectedDownloadSelector.status === PDFGeneration.Ready;

    const onGeneratPDF = useCallback(
      (downloadPayload: DownloadRequestRemote): void => {
        downloadPayload.jaguar_doc_logo_with_text = projectConfig.jaguar_doc_logo_with_text;
        downloadPayload.maintenance_report_cover_fallback = projectConfig.maintenance_report_cover_fallback;
        simpleFetchContract(contract.contract_ref).then(
          // simpleFetchMMRAppendices(contract.contract_ref, payload.snapshot.id);
          // console.log('mmrAppendices: ', mmrAppendices);
          (gridContract) => {
            // we fetch the contract first in case any aws links have expired... we could of course check the state of the contract in the state and try to see
            // if the image urls have expired yet, but we would be comparing it to the local computer time (I think), which will be a source of frustration.
            downloadPayload.contract = gridContract;
            forkJoin(
              {
                distribution: simpleFetchDistribution({ contractId: contract.id, contractRef: contract.contract_ref }),
                appendices: simpleFetchMMRAppendices({ contractId: contract.id, contractRef: contract.contract_ref, snapshotId: downloadPayload.snapshot.id })
              }
            )
              .subscribe(response => {
                downloadPayload.distribution = response.distribution.data;
                downloadPayload.appendices = response.appendices.data;
                dispatch(downloadRequestRemote(downloadPayload));
              });
          }
        )
      }, [dispatch, projectConfig.jaguar_doc_logo_with_text, projectConfig.maintenance_report_cover_fallback, contract]
    );

    return (
      <>
        {enablePersonalVisibilitySettings && !forceHidePersonalVisibilitySettingsMenu && (configurations?.visibilityMenu) &&
          //HIDE THIS FOR NON JAGUAR STAFF
          <RightVisibilityMenu
            //uses configuration fed into withPageContext - used for individual interface pages
            visibilitySettings={configurations.visibilityMenu({ visibilitySettings: selectedPersonalContractSettings, contract, portfolio, inReportContext: contractReportContext, frozenFor: selectFrozenFor })}
            defaultOnClick={defaultToggleVisibilityControls}
            appliedTo={portfolioOrContract}
          />
        }

        {(configurations?.customVisibilityMenu) && !reportVisibilityModalOpen &&
          <RightContextButton
            // Just sets a button that will alter a boolean which a particular 'ReportComponent' (i.e. any component passed to this HOC wrapper) 
            // can use to know when to show it's own custom context menu in place of the RightVisibilityMenu.
            // Right now the ContractReport component does this - delegating to ContractReportVisibility, which uses a custom Dialogue rather than 
            // RightVisibilityMenu.  The custom dialogue effectively sets some state which 'switches' to the appropriate visibility menu (the visibility menus
            // are first obtained in ContractReportVisibility through useGetContractReportingMenu()) and then 'feeds' it the contractVisibilitySettings, 
            // just as the RightVisibilityMenu component above feeds the personal visibilitySettings to the same 'menu' passed in to HOC as config. 
            // The ContractReportVisibility Dialogue renders the VisibilityControls component - and the RightVisibilityMenu component above does the same 
            // - so the VisibilityControls component is where the contract report visibility and personal visibility settings 'merge'
            // The RightVisibilityMenu therefore is analagous to the ContractReportVisibility Dialogue component.
            // visibilitySettings are a dictionary of values defining the parameters to update on the backend (for 'fixed' sections defined on the server
            // in PersonalContractSettings or MonthlyMaintenanceReportConfiguration) and the current values and it is generated by passing the personal
            // or contract visibility settings to the menu 'template' - defined somewhere like logsRightVisibilityMenu.ts
            showSettings={true}
            onShowSettings={() => dispatch(changeSetting("reportVisibilityModalOpen", true))}
          />
        }

        <div className={className}>
          <ReportComponent
            {...props}
            animate={animate}
            className={className}
            frozenFor={selectFrozenFor}
            // passing forceHidePersonalVisibilitySettingsMenu and enablePersonalVisibilitySettings give report components and opportunity to formulate their own logic
            // where it's not enough to simply swap the contract and personal visibility settings (which happens in the visibilitySettings argument immediately below) 
            // - e.g. the meterreadings where it doesn't rely on the visibility settings dict alone but also incorporates some custom state holding lists of ids
            // for personal view settings
            forceHidePersonalVisibilitySettingsMenu={forceHidePersonalVisibilitySettingsMenu}
            enablePersonalVisibilitySettings={enablePersonalVisibilitySettings}
            contractReportContext={contractReportContext}
            // FOR THIS NEXT BLOCK WE COULD PROBABLY ALLOW EACH COMPONENT TO IMPLEMENT - TURNING THE TERNARY INTO A SELECTOR IF APPROPRIATE - THE END RESULT 
            // IS THAT THIS withPageContext component wouldn't rely on the selectedVisibilitySettings and each child could just listen to the appropriate changes
            // with a custom selector - which might help prevent refresh of all pages when updating contract report visibility settings

            // In the visibilitySettings below we use the contract visibility settings if they're not a user meant to adjust their own settings,
            // then fallback to any explicity passed ones - the major use case being the ContractReport.tsx page, where even if we have access
            // to our own personal settings, we want to use the 'contract' ones.  Finally we fall back to any personal settings.

            visibilitySettings={!enablePersonalVisibilitySettings ? selectedVisibilitySettings.data : visibilitySettings ? visibilitySettings : selectedPersonalContractSettings}
            onGeneratPDF={onGeneratPDF}
            pdfStatus={selectedDownloadSelector.status}
            isDownloadReady={isDownloadReady()}
            isDownloadRequested={isDownloadRequested()}
            documentBase64={selectedDownloadSelector.pdfBase64}
            contract={contract}
            portfolio={portfolio}
          />
        </div>
      </>
    )
  }

  WithPageContext.displayName = `WithPageContext(HoC)(${getDisplayName(ReportComponent)})`;

  return WithPageContext;
}

const getDisplayName = (WrappedComponent: any) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPageContext;
