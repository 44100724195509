import React, { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, { Column, Paging, Grouping, FilterRow, RemoteOperations } from "devextreme-react/data-grid";
import { withResizeDetector } from "react-resize-detector";
import { IconButton } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { getRemoteOpParamsAndContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';
import AddIcon from "@material-ui/icons/Add";

// Own
import { filterRowConfig } from "components/ContractInFocus/Models/Grid";
import { isPrimitive } from "services/API/API.helper";
import { sentenceCase } from "../../../helpers/String/String.helper";
import {
  panableView,
  sliceSchedulePrintDataGrid,
} from "components/Schedulers/ScheduleDataGrid/Helper/scheduleDataGridHelper";
import PlannerView from "../PlannerGrid/PlannerView";
import { ColumnProps } from "components/ContractInFocus/interfaces/dataGridColumn.interface";
import * as contractInFocusSelectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { useGetPanners, setInitialViewFrom } from "components/ContractInFocus/Hooks/UsePannable/usePannable";
import { Snapshot } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { Dictionary, Primitive } from "components/Common/Interfaces/Entity.interface";
import { APIPrivileges } from "services/Interface/Interface";
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { dateUDF, getPeriodBetween } from "components/Common/Utils/Dates.js";


// Styles
import { ScheduleWrapper } from "./SchedulerDataGridStyles";
import "./SchedulerDataGrid.scss";
import { FilterControlProperties } from "components/Schedulers/Scheduler";

// const sortDataBasedOnArchive = (recordA: any, recordB: any) => {
//   if (recordA.archived && !recordB.archived) {
//     return 1;
//   }
//   if (!recordA.archived && recordB.archived) {
//     return -1;
//   }
//   return 0;
// };

type ScheduleDataGridProps = {
  contextIdString: string;
  contract: SiteContract;
  portfolio?: HydratedPortfolio;
  //data: any[];
  //totalCount?: number;
  width: number;
  onNewItem: () => void;
  globalOnly?: boolean;
  wrapperRef: any;
  openRowModal: boolean;
  setOpenRowModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshSignal: number;
  refreshGrid: React.DispatchWithoutAction;
  showPlannerView: boolean;
  contractReportContext?: boolean;
  overrideViewFrom?: number;
  viewFrom: number;
  setViewFrom: React.Dispatch<React.SetStateAction<number>>;
  isPDFRequest?: boolean;
  extraFilterQueryParams?: FilterControlProperties;
  DetailModal: React.FC<any>;
  clickableCaptionColumns?: string[];
  getButtonCellTemplate: (e: any) => JSX.Element;
  getHeaderButtonCellTemplate?: (e: any, contractRef?: string, portfolioId?: number, query_params?: any) => JSX.Element;
  buttonCellWidth: number;
  //getButtonCellTemplate: () => (props: any) => JSX.Element;
  visibleFixedColumns: string[];
  addToCellClassName?: (data: any) => string;
  generateColumnHeaders: (months: string[]) => ColumnProps[];
  generateColumnHeadersForPlannerView?: (months: string[], portfolio?: HydratedPortfolio, reportContext?: boolean) => ColumnProps[];
  pageSize?: number;
  initialEditModalIndex?: number;
  CellRender: {
    (cellData: any): JSX.Element;
    propTypes: {
      cellData: PropTypes.Validator<object>;
    };
  }
  ControlSwitches?: () => JSX.Element;
  showArchived: boolean,
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
  fetchInfo: ({ args, query_params }: { args?: any, query_params?: any }) => Promise<{
    data: any;
    metadata: any;
    permissions: APIPrivileges;
    totalCount?: number;
  }>
  fetchPreflightInfo?: ({ args, query_params }: { args?: any, query_params?: any }) => Promise<PreFlightListInfo> // just in case we want to pass args and params later
  processData?: (data: any) => any;
  remoteOperations?: boolean;
  contractRefDataField?: string;
  reload: number;
  passedInPeriod?: Period;
  showContractsColumn?: boolean;
  handleOnRowPrepared?: (e: any) => void;
  [idx: string]: any;
};

const ScheduleDataGrid = ({
  contextIdString,
  contract,
  portfolio,
  //data,
  //totalCount,
  width,
  onNewItem,
  openRowModal,
  setOpenRowModal,
  wrapperRef,
  refreshGrid,
  showPlannerView,
  overrideViewFrom,
  contractReportContext,
  extraFilterQueryParams,
  viewFrom,
  setViewFrom,
  isPDFRequest,
  DetailModal,
  clickableCaptionColumns,
  getButtonCellTemplate,
  getHeaderButtonCellTemplate,
  buttonCellWidth,
  visibleFixedColumns,
  addToCellClassName,
  generateColumnHeaders,
  generateColumnHeadersForPlannerView,
  initialEditModalIndex,
  CellRender,
  ControlSwitches,
  showArchived,
  setShowArchived,
  fetchInfo,
  fetchPreflightInfo, // we pass this as well as fetch info as we can't really set something that will change state on the grid load method, so a light weight preflight seems indicated
  processData,
  //setExtraQueryParams,
  remoteOperations,
  pageSize,
  contractRefDataField,
  reload,
  passedInPeriod,
  showContractsColumn,
  refreshSignal,
  handleOnRowPrepared,
  ...props
}: ScheduleDataGridProps) => {
  const reduxSelectedPeriod = useSelector(contractInFocusSelectors.contractOrPortfolioPeriodSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
  //const [selectedPeriod, setSelectedPeriod] = useState<Period>();
  const scheduleSelectedPeriod = passedInPeriod || reduxSelectedPeriod;
  const selectedFocusedSnapshot = useSelector(
    contractInFocusSelectors.contractOrPortfolioSnapshotSelector({ contractId: contract?.id, portfolioId: portfolio?.id })
  );
  //const [showArchived, setShowArchived] = useState(false);
  const storedData = useRef<any>();
  const editRowContract = useRef<SiteContract>();
  const selectedItem = useRef<any>();
  const numberOfFixedColumns = 2;
  const [dataSource, setDataSource] = useState<DataSource>();
  const [canWrite, setCanWrite] = useState(false);
  const storedLoadOptions = useRef<any>();
  const dataGridRef = useRef<any>(null);
  const plannerViewDataRef = useRef<any[]>();
  const [baseQueryParams, setBaseQueryParams] = useState<Dictionary<Primitive>>();
  const fullQueryParams = useRef<Dictionary<Primitive>>(); // this is NOT set to drive the grid params but to track them
  // so that other components might 
  const getSetViewFrom = [viewFrom, setViewFrom];
  const [panned, setPanned] = useState(false);
  const [viewableColumns, setViewableColumns] = useState(0);
  const [columnMeta, setColumnMeta] = useState<ColumnProps[]>([]);
  const [printColumnMeta, setPrintColumnMeta] = useState<ColumnProps[]>([]);
  const [gridColumnMeta, setGridColumnMeta] = useState<ColumnProps[]>([]);
  const [plannerColumnMeta, setPlannerColumnMeta] = useState<ColumnProps[]>([]);
  const cannotPanLeft = useRef(false);
  const cannotPanRight = useRef(false);
  const { onPanLeft, onPanRight } = useGetPanners({
    dataLength: columnMeta.length - numberOfFixedColumns,
    getSetViewFrom,
    setPanned,
    viewLength: viewableColumns,
    lowerLimitReachedRef: cannotPanLeft,
    upperLimitReachedRef: cannotPanRight
  });

  const wrapperHeight = window.innerHeight;//wrapperRef.current?.clientHeight;

  const thisShowContractsColumn = portfolio || showContractsColumn;

  //const showColumns = (width: number) => Math.round((width * 0.6) / 125);
  //const showColumns = (width: number, selectedFocusedSnapshot: Snapshot) => selectedFocusedSnapshot ? 12 : Math.round((width >= 750 ? width * 1.6 : width / 1.3) / 200);
  const showColumns = (width: number, selectedFocusedSnapshot: Snapshot) => selectedFocusedSnapshot ? 12 : Math.round((width >= 750 ? width * 1.4 : width / 1.3) / 200);
  const offsetTwelthes = selectedFocusedSnapshot ? 12 : 8;
  const selectedMonth = useRef<string>();
  const selectedYear = useRef<string>();
  const selectedMonthPeriod = useRef<Period>();

  // useEffect(() => {
  //   console.log('refreshing grid called ' + refreshSignal + ' times');
  // }, [refreshSignal]);

  useEffect(() => {
    if (scheduleSelectedPeriod?.months) {
      const { months } = scheduleSelectedPeriod;
      const date = selectedFocusedSnapshot
        ? moment(selectedFocusedSnapshot.frozen_for, "YYYY-MM-DD").toDate()
        : undefined // CHANGE!!
      date &&
        setPrintColumnMeta(
          sliceSchedulePrintDataGrid(
            generateColumnHeaders(months),
            scheduleSelectedPeriod.months[0],
            date
          )
        );
      const newColumnHeaders = generateColumnHeaders(scheduleSelectedPeriod?.months);
      setColumnMeta(newColumnHeaders);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [scheduleSelectedPeriod, selectedFocusedSnapshot, generateColumnHeaders]);

  useEffect(() => {
    setBaseQueryParams(
      {
        start_date: scheduleSelectedPeriod?.start_date,
        end_date: scheduleSelectedPeriod?.end_date,
        ...extraFilterQueryParams
      }
    )
  }, [scheduleSelectedPeriod, extraFilterQueryParams]);

  useEffect(() => {
    fetchPreflightInfo && fetchPreflightInfo({}).then(response => setCanWrite(response.canCreate))
  }, [fetchPreflightInfo]);

  useEffect(() => {
    if (columnMeta.length) {
      setInitialViewFrom(
        {
          dataGrid: dataGridRef.current?.instance,
          panned,
          dataArray: columnMeta.slice(1, -1),
          offsetTwelthes,
          setViewFrom,
          selectedFocusedSnapshot,
          viewLength: viewableColumns,
          findColBy: 'caption',
          overrideViewFrom: overrideViewFrom
        }
      )
    }
    // dependencies here are important - at least without the viewLength one the calcs are inaccurate so it must change between renders!
  }, [panned, columnMeta, offsetTwelthes, setViewFrom, selectedFocusedSnapshot, viewableColumns, overrideViewFrom]);

  // For now we're using query_params instead
  // useEffect(() => {
  //   const thisGrid = dataGridRef.current;
  //   console.log('filters: ', thisGrid?.instance.getCombinedFilter(true))
  // }, [dataSource]);

  useEffect(() => {
    const showColumnCount = showPlannerView ? 12 : showColumns(!width ? 1000 : width, selectedFocusedSnapshot);
    setViewableColumns(showColumnCount);
    columnMeta.length &&
      setGridColumnMeta(panableView(columnMeta, viewFrom, showColumnCount));
    // alert('showColumnCount: ' + showColumnCount);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [width, viewFrom, setViewableColumns, columnMeta]);

  useEffect(() => {
    const months = gridColumnMeta.filter(x => !x.primary).map(x => x.dataField)
    generateColumnHeadersForPlannerView && setPlannerColumnMeta(generateColumnHeadersForPlannerView(months, portfolio, contractReportContext));
  }, [gridColumnMeta, portfolio, contractReportContext, generateColumnHeadersForPlannerView]);

  const handleLoad = useCallback((loadOptions: any) => {
    // NOTE!!!!!! THERE IS ONE !!!!MAJOR!!!! RULE IN ANY LOAD FUNCTION FOR THE GRID - DO NOT SET ANY VALUES (E.G. STATE) THAT COULD CAUSE THE GRID TO RE-RENDER
    // INSTEAD, IF NEED BE, SET REF VALUES AND THEN WATCH THOSE OR REFRESH TO CHECK FOR THOSE IN OTHER FUNCTIONS THAT MAY RELY ON THEM.
    // IF RETRIEVING DATA INITIALISES A RELOAD, A LOOP WILL BE ENTERED - AND IT WON'T CRASH AS IT WILL TAKE SOME TIME TO GET EACH RESPONSE AND RELOAD
    // SO IT WON'T BE SEEN AS A RECURSION.

    // NOTE !! SEE FINANCIALS GRID IN CASE YOU NEED TO PRESERVE CERTAIN LOAD OPTIONS, WHEN THERE MAY BE OTHER FILTERS APPLIED
    // IF THERE ARE MORE COLUMNS TO GROUP BY IN FUTURE.

    const grid_params = getRemoteOpParamsAndContractScopeParams({ loadOptions })
    storedLoadOptions.current = loadOptions;
    if (baseQueryParams !== undefined && scheduleSelectedPeriod) {
      let query_params = baseQueryParams;
      if (grid_params) {
        query_params = {
          ...query_params,
          ...grid_params,
          must_refresh: refreshSignal || reload
        }
      }
      fullQueryParams.current = query_params;
      const gridData = fetchInfo({
        query_params: query_params
      }).then((response) => {
        let thisData = response.data;
        if (processData) {
          thisData = processData(thisData);
        }
        plannerViewDataRef.current = [...thisData];
        if (!showArchived && !selectedFocusedSnapshot) {
          thisData = thisData.filter((x: any) => !x.archived);
        }
        // else { // NOT REALLY REQUIRED WITH CURRENT ARCHIVE GROUPING - BUT LEAVING HERE AS A REMINDER THAT IF 
        // A POST PROCESSING SORT ORDER IS USED, THE ORDER HAS TO REFLECT THE ORDER EXPECTED, WHICH THE sortDataBasedOnArchive function currently does.
        //   thisData = thisData.sort(sortDataBasedOnArchive)
        // }
        storedData.current = thisData;
        const info = {
          totalCount: response.totalCount || thisData.length,
          data: thisData
        }
        return info
      })

      return gridData;
    }
    return new Promise((resolve) => resolve({ // NOTE!! we have to return a promise here as returning a plain object will only permit an array, not a dict with data and totalCount
      'data': storedData.current || [],
      'totalCount': storedData.current.length || 0
    }))

  }, [fetchInfo, processData, selectedFocusedSnapshot, showArchived, baseQueryParams, scheduleSelectedPeriod, refreshSignal, reload]);

  useEffect(() => {
    const custom = new CustomStore({
      load: handleLoad,
    });
    setDataSource(new DataSource({ store: custom }));
  }, [handleLoad]);

  const handlePanLeft = (): void => {
    onPanLeft();
  };

  const handlePanRight = (): void => {
    onPanRight();
  };

  const renderFixedColumnCell = (cellData: any) => {
    let className = 'fixed-col-value';
    if (addToCellClassName) {
      className = `${className} ${addToCellClassName(cellData)}`;
    }
    const thisValue = cellData.value?.name || cellData?.value;
    // isPrimitive is there to combat issue where DX seems to pass the wrong values to the columns temporarily when the column layout changes in react, 
    // Temporarily renderFixedColumnCell appeared to be passed the visits column data.
    return <span className={className}>{isPrimitive(thisValue) ? thisValue : ''}</span>
  }

  const onCellClick = (e: any) => {
    // ? Shouldn't be in snapshot mode, datafield should exists, shouldn't be one of the primary fixed columns
    const clickableCaptionColumn = clickableCaptionColumns && clickableCaptionColumns.indexOf(e.column.dataField) >= 0;
    const isCellClickable = () =>
      !selectedFocusedSnapshot &&
      e.rowType === "data" &&
      e.column?.dataField &&
      (visibleFixedColumns.indexOf(e.column.dataField) === -1 || clickableCaptionColumn);

    if (isCellClickable()) {
      // best to grab fresh data for the visit list modal immediately (before starting to render the datagrid) to improve the look of the load
      // it also means we get refreshed data.  So we may as well get the meta and permissions at the same time and pass them to the list component.
      // also while we could iterate the cell data to collect all the visits for the row when clicking on contractor, it's more robust to use the 
      // exact same route for visits that the visitList component itself will use.
      const isCellEmpty = e.data && (!e.data[e.column?.caption] && !clickableCaptionColumn);
      const shouldOpenModal = !(isCellEmpty && !canWrite); // open if: a) The cell isn't empty or b) the cell is empty and the user can write
      if (!clickableCaptionColumn) {
        const dateLabel = e.column?.caption;
        [selectedMonth.current, selectedYear.current] = dateLabel.split(" ");
        const selectedMonthString = moment(selectedYear.current + '-' + selectedMonth.current + '-01', "YYYY-MMM-DD").format(dateUDF);
        const thisPeriod = getPeriodBetween({
          startDate: selectedMonthString,
          endDate: selectedMonthString,
          wholeMonths: true // with this we will get the start and end dates of the month in question
        })
        selectedMonthPeriod.current = thisPeriod;
      }
      if (shouldOpenModal) {
        selectedItem.current = e.data;
        if (portfolio) {
          const rowContract = portfolio?.contracts?.find(x => x?.id.toString() === e.data.contract.toString())
          editRowContract.current = rowContract;
        }
        setOpenRowModal(true);
      }
    }
  };

  useEffect(() => {
    if (!openRowModal) {
      selectedMonth.current = undefined;
      selectedYear.current = undefined;
      selectedMonthPeriod.current = undefined;
    }
  }, [openRowModal])

  const getGroupCaption = (e: any) =>
    e.value ? "Out of contract" : "In contract";

  const ButtonCellTemplate = (e: any) => getButtonCellTemplate(e);
  const HeaderButtonCellTemplate = getHeaderButtonCellTemplate ? (e: any) => getHeaderButtonCellTemplate(e, contract?.contract_ref, portfolio?.id, fullQueryParams.current) : undefined;

  const isPrintColumnMetaReady = () =>
    !!printColumnMeta && printColumnMeta.length !== 2;

  const [hideForPrint, setHideForPrint] = useState(true);
  const [showFilter, setShowFilter] = useState(true);

  return (
    <ScheduleWrapper>
      <div className={`buttons ${hideForPrint ? 'no-print' : ''}`}>
        {ControlSwitches && <ControlSwitches />}
        {canWrite ? (
          <IconButton onClick={onNewItem}>
            <AddIcon />
          </IconButton>
        ) : null}
        <IconButton onClick={handlePanLeft} disabled={cannotPanLeft.current}>
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton onClick={handlePanRight} disabled={cannotPanRight.current}>
          <NavigateNextIcon />
        </IconButton>
      </div>
      {!showPlannerView && scheduleSelectedPeriod && <DataGrid
        ref={dataGridRef}
        id={`scheduleGridContainer${contextIdString}`} // contextIdString is necessary to be specific enough to override dx styles etc and to select, yet allow more than one grid per page
        className={`${hideForPrint ? 'no-print' : ''} scheduleGridContainer`}
        dataSource={dataSource}
        showBorders={true}
        showColumnLines={true}
        onCellClick={onCellClick}
        onRowPrepared={handleOnRowPrepared}
      //height={0.6 * wrapperHeight}
      >
        {pageSize && <Paging defaultPageSize={pageSize} />}
        <FilterRow
          visible={showFilter}
          applyFilter={filterRowConfig.applyFilter}
        />
        {remoteOperations && <RemoteOperations
          groupPaging={false}
          grouping={true}
          filtering={true}
          sorting={true}
          paging={!!pageSize}
        />}

        {(showArchived || selectedFocusedSnapshot) && <Grouping autoExpandAll={true} />}

        {(showArchived || selectedFocusedSnapshot) && <Column
          dataField="archived"
          groupIndex={0}
          groupCellRender={getGroupCaption}
        />}
        {!selectedFocusedSnapshot && <Column
          type="buttons"
          width={buttonCellWidth}
          cellRender={ButtonCellTemplate}
          headerCellRender={HeaderButtonCellTemplate}
        ></Column>}
        {thisShowContractsColumn && <Column
          dataField={contractRefDataField || "contract_ref"}
          caption="Contract"
          width="80px"
        />}

        {gridColumnMeta.map((column) => {
          if (visibleFixedColumns.indexOf(column?.dataField) !== -1) {
            return (
              <Column
                key={column.dataField}
                alignment={column.alignment}
                width={column.width}
                dataField={column.dataField}
                visible={column.dataField !== "archived"}
                caption={sentenceCase(column.caption)}
                cellRender={renderFixedColumnCell}
                cssClass={clickableCaptionColumns?.includes(column.dataField) ? "item-clickable" : null}
              />
            );
          } else if (column) {
            return (
              <Column
                key={column.dataField}
                alignment={column.alignment}
                width={column.width}
                dataField={column.dataField}
                caption={sentenceCase(column.dataField)}
                cellRender={CellRender}
                allowSorting={false}
              />
            );
          }
        })}
      </DataGrid>}
      {/* {isPrintColumnMetaReady() && selectedFocusedSnapshot && !showPlannerView && ( */}
      {isPrintColumnMetaReady() && selectedFocusedSnapshot && !showPlannerView && (
        <div className="no-screen">
          <PlannerView columnMeta={plannerColumnMeta} baseQueryParams={baseQueryParams} fetchInfo={fetchInfo} records={plannerViewDataRef.current} contractReportContext={contractReportContext} />
        </div>
      )}

      {isPrintColumnMetaReady() && showPlannerView && (
        <PlannerView columnMeta={plannerColumnMeta} baseQueryParams={baseQueryParams} fetchInfo={fetchInfo} records={plannerViewDataRef.current} contractReportContext={contractReportContext} />
      )}

      {openRowModal ? (
        <DetailModal
          contract={contract || editRowContract.current}
          portfolio={portfolio}
          canWrite={canWrite}
          initialIndex={initialEditModalIndex === 0 ? 0 : initialEditModalIndex || 1}
          setOpen={setOpenRowModal}
          open={openRowModal}
          initialContextObject={selectedItem.current}
          initialMonth={selectedMonth.current}
          initialYear={selectedYear.current}
          refreshGrid={refreshGrid}
          selectedPeriod={selectedMonthPeriod.current || scheduleSelectedPeriod}
          {...props}
        />
      ) : null}
    </ScheduleWrapper>
  );
};

export default withResizeDetector(ScheduleDataGrid);
