import Cookies from "universal-cookie";
import { store } from "../../store/store";
import { authenticationService } from "../Auth/AuthService";
import { sentenceCase } from "../../helpers/String/String.helper";
import { NOTIFICATION_ERROR } from "../../components/Notification/Constants/constants";
import { addNotification } from "../../components/Notification/Actions/Notification.actions";
import { snapshotContractApis } from "services/API/common/contractAPIs";
import { saveRouteBeforeLogout, nonRedirectRoute } from "../../Routes.js";
import axios from "axios";

const cookies = new Cookies();

const onUnauthenticated = () => {
  // get the current window location and store it
  saveRouteBeforeLogout();
  // console.log('unauthenticated... 1234');
  authenticationService.logout();
};

export const connectContractHistory = (config, contractApis, store) => {
  const isContractAPIRoute = () =>
    contractApis.find(path => config.url.indexOf(path) !== -1);

  if (!isContractAPIRoute()) {
    return config;
  }

  const { contractInFocus, portfolioInFocus } = store.getState();

  const portfolioSnapshotInFocus = portfolioInFocus.frozenHistory?.focus;
  const contractSnapshotInFocus = contractInFocus?.frozenHistory?.focus;

  let frozenHistory;

  // we do nothing here to prevent there being a portfolioSnapshotInFocus AND a contractSnapshotInFocus, but the backend SHOULD complain
  // and return no data if that occurs.

  if (portfolioSnapshotInFocus) {
    frozenHistory = portfolioInFocus.frozenHistory;
    config.params = {
      ...(config.params || {}),
      "frozen-for-portfolio": portfolioInFocus.portfolio.id
    };
  }
  if (contractSnapshotInFocus) {
    frozenHistory = contractInFocus.frozenHistory;
    config.params = {
      ...(config.params || {}),
      "frozen-for-contract": contractInFocus.contract.id
    };
  }

  if ((frozenHistory && portfolioSnapshotInFocus) || contractSnapshotInFocus) {
    config.params = {
      ...(config.params || {}),
      "frozen-for": frozenHistory.data[frozenHistory.focus].frozen_for
    };
  }

  return config;
};

const onRequest = config => {
  const token = cookies.get("token");
  const uri = axios.getUri(config);
  try {
    const targetURL = new URL(uri, config.baseURL);
    const currentOrigin = window.location.origin;
    const targetHostName = targetURL.hostname;
    const targetOrigin = targetURL.origin;
    if (targetHostName === "localhost" || currentOrigin === targetOrigin) {
      // NOTE: PUT THIS BACK, AS SOON AS YOU HAVE SORTED OUT API KEY FOR PDF SERVICE AGAINST BACKEND AND PDF PRODUCTION LICENSE SYSTEM
      !!token && (config.headers.Authorization = `Token ${token}`);
    }
  } catch (err) {
    console.log("err: ", err);
  }
  return connectContractHistory(config, snapshotContractApis, store);
};

const onResponseSuccess = response => {
  try {
    const metadata =
      response.data?.metadata || response.data?.results?.metadata;
    if (metadata && metadata.errors && metadata.errors.length) {
      store.dispatch(
        addNotification({
          message: metadata.errors[0],
          type: NOTIFICATION_ERROR
        })
      );
    }
    if (
      response.config.params &&
      response.config.params["frozen-for"] &&
      metadata &&
      !metadata.historical
    ) {
      store.dispatch(
        addNotification({
          message: `data fetched from ${response.config.url} is expected to be frozen for ${response.config.params["frozen-for"]} but metadata indicates the response data is not historical`,
          type: NOTIFICATION_ERROR
        })
      );
    }
  } catch (e) {
    console.log(e);
  }

  return response;
};

// eslint-disable-next-line no-unused-vars
const errorIncludesCode = (error, code) => {
  !!error && typeof error === "string" && error.includes(code);
};

const getReadableEntityFromURL = error => {
  if (error.response) {
    const url = error.response.config.url;
    return (
      url
        .replace(process.env.REACT_APP_API_URL, "")
        .replace("/?format=json", "")
        .substr(1, url.length)
        .replace("/", " - ") + " "
    );
  } else {
    return error;
  }
};

export const onResponseError = err => {
  const status = err.status || (err.response ? err.response.status : 0);
  if (
    !nonRedirectRoute.includes(store.getState().common.route) &&
    (status === 401 || errorIncludesCode("401"))
  ) {
    onUnauthenticated();
  }

  if (
    !nonRedirectRoute.includes(store.getState().common.route) &&
    err.response
  ) {
    const message404 = status === 404 ? getReadableEntityFromURL(err) : "";
    dispatchWhenBodyExists(err, message404);
  }

  return Promise.reject(err);
};

const onResponseErrorWithoutDispatch = err => {
  const status = err.status || (err.response ? err.response.status : 0);
  if (
    !nonRedirectRoute.includes(store.getState().common.route) &&
    (status === 401 || errorIncludesCode("401"))
  ) {
    console.log("err: ", err);
    onUnauthenticated();
  }
  return Promise.reject(err);
};

const getErrorMessageFromObjectInResponse = err => {
  console.log("getErrorMessageFromObjectInResponse");
  let errorMessage = "";
  const errorKeys = Object.keys(err.response.data);
  const messageObject = err.response.data[errorKeys[0]];
  if (Array.isArray(messageObject) && typeof messageObject[0] === "string") {
    errorMessage += messageObject.join(". ");
  } else if (typeof messageObject === "object") {
    errorMessage += `${JSON.stringify(err.response.data[errorKeys[0]])}`;
  } else {
    errorMessage += `${errorKeys} ${err.response.data[errorKeys[0]]}`;
  }
  return errorMessage;
};

const isErrorInHTML = err => {
  return typeof err === "string" && err.toLowerCase().indexOf("html");
};

const getMessageFromStatusText = err => {
  console.log("getMessageFromStatusText");
  let msg = err.response.statusText;
  // if (err.response.data) {
  //   msg = msg //+ " and detail: " + JSON.stringify(err.response.data);
  // }
  return msg;
};

const dispatchWhenBodyExists = (err, message404) => {
  console.log("dispatchWhenBodyExists");
  try {
    const errorMessage = isErrorInHTML(err.response.data)
      ? getMessageFromStatusText(err)
      : getErrorMessageFromObjectInResponse(err);
    dispatchError(sentenceCase(message404) + sentenceCase(errorMessage));
  } catch (badErr) {
    // console.log('irregular error format: ', badErr);
    dispatchError(err);
  }
};

const dispatchError = error => {
  store.dispatch(addNotification({ message: error, type: NOTIFICATION_ERROR }));
};

export const setupInterceptors = () => {
  return API => {
    API.interceptors.request.use(onRequest);
    API.interceptors.response.use(onResponseSuccess, onResponseError);
  };
};

export const setupInterceptorsWithoutAutoMessageDispatch = () => {
  return API => {
    API.interceptors.request.use(onRequest);
    API.interceptors.response.use(
      onResponseSuccess,
      onResponseErrorWithoutDispatch
    );
  };
};
