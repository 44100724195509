import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import DataGrid, { Column, Editing, Paging, RequiredRule } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Paper } from "@material-ui/core";
import { Button } from 'reactstrap';

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { IAudit } from "./Audit.interface";
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import GridHeader from "../../Common/Components/GridHeader/GridHeader";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from '../../../helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import withPageContext, { WithPageContextProps } from "../HoC/WithPageContext/WithPageContext";
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";
import { auditRightVisibilityMenu } from "./Models/auditRightVisibilityMenu";
import PrintDataGrid from "components/Common/Components/PrintDataGrid/PrintDataGrid";
import { auditColumns, portfolioAuditColumns } from "./Models/auditColumns";
import { saveDateFormat } from "components/Common/Utils/Dates";
import { reportSectionStructure, reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintTitle } from "../Components/ContractPrintTitle/ContractPrintTitle";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import Header from "components/Common/Components/GridHeader/GridHeader.js";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import { getCSRF, prependToAPI } from "services/API/API.helper";
import { includeCSRFHeader } from "components/ContractInFocus/Interfaces/ContractInFocus.interfaces";
import { exportInsuranceAudits } from "components/ContractInFocus/Maintenance/Services/MaintenanceService";



const getContractEndpoint = (contractId: string | number) => `contracts/${contractId}/insurance-audits/?format=json`;
const getPortfolioEndpoint = (portfolioId: string | number) => `portfolios/${portfolioId}/insurance-audits/?format=json`;


interface AuditProps extends WithPageContextProps { }

const Audit: React.FC<AuditProps> = ({ contract, portfolio, frozenFor, visibilitySettings, sectionNumberMap }) => {

  const csrfToken = getCSRF();

  let getEndpoint: any;
  if (contract) {
    getEndpoint = getContractEndpoint;
  } else if (portfolio) {
    getEndpoint = getPortfolioEndpoint;
  }

  const [dataSource, setDataSource] = useState<DataSource>();
  const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
  const [, setContentReady] = useState(false);

  const allAuditsTitle = reportSubtitles.AllAudits;

  const [subTitleIndex, setSubTitleIndex] = useState<string>('');
  const tocs = useSelector(tocsSelector);

  useEffect(() => {
    const thisSubTitleIndex = getSubTitleNumber(
      allAuditsTitle,
      tocs.flatTocsLookup,
    )
    thisSubTitleIndex && setSubTitleIndex(thisSubTitleIndex);
  }, [tocs, allAuditsTitle])

  useEffect(() => {
    if (contract) {
      const custom = new CustomStore({
        key: "id",
        load: () => CommonAPIService.getAll<IAudit>(
          getEndpoint,
          setMetadata,
          contract.contract_ref
        ),
        insert: values => CommonAPIService.create<IAudit>(
          {
            getEndpoint,
            contract,
            values
          }
        ),
        // @ts-ignore
        remove: key => CommonAPIService.del<IAudit>(getEndpoint, contract.contract_ref, key),
        update: (id, values) => CommonAPIService.update<IAudit>(getEndpoint, contract.contract_ref, id, values)
      });

      setDataSource(
        new DataSource({
          store: custom
        })
      );
    }
  }, [contract, getEndpoint]);

  useEffect(() => {
    if (portfolio) {
      const custom = new CustomStore({
        key: "id",
        load: () => CommonAPIService.getAll<IAudit>(
          getEndpoint,
          setMetadata,
          portfolio.id
        ),
        insert: values => CommonAPIService.create<any>({ getEndpoint, portfolio, values }),
        // @ts-ignore
        remove: key => CommonAPIService.del<any>(getEndpoint, portfolio.id, key),
        update: (id, values) => CommonAPIService.update<any>(getEndpoint, portfolio.id, id, values)
      });

      setDataSource(
        new DataSource({
          store: custom
        })
      );
    }
  }, [portfolio, getEndpoint]);

  const handleDateColumns = (data: any): void => {
    ["report_inspection_date", "action_required_by", "date_completed", "next_inspection_date"].map(
      (x) => {
        if (data[x]) {
          data[x] = saveDateFormat(data[x])
        }
      }
    )
  };

  const handleRowInserting = (values: any): void => {
    handleDateColumns(values.data);
  };

  const handleRowUpdating = (values: any): void => {
    handleDateColumns(values.newData);
  }

  const getColumnPropsExt = (field: string): ColumnProps | undefined => {
    return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
  }

  const handleEditingStart = () => {
    if (metadata) {
      setMetadata({ ...metadata, activeMeta: metadata.PUTMeta });
    }
  }

  const handleRowUpdated = () => {
    if (metadata) {
      setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
    }
  }

  const renderColIfMetaDataDefined = (fieldName: string, requiredOveride: boolean, colPropsOverride = {}) => {
    const metaColProps = getColumnPropsExt(fieldName);
    if (metaColProps) {
      const colProps = { ...metaColProps, ...colPropsOverride }
      return (<Column {...colProps}>
        {requiredOveride && <RequiredRule />}
      </Column>
      )
    }
  }

  if (!visibilitySettings.show_audit_table) {
    return <></>
  }

  const PrintHeading = () => <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.Audit} />;

  const handleGoToInsuranceAuditImport = () => {
    //window.open("api/admin/core/openppmtask/import/?contract_ref=" + contract.contract_ref, "_self");//, "_blank"
    if (portfolio) {
      window.open(`${prependToAPI}api/admin/core/insuranceaudit/import/?portfolio=${portfolio.id}`, "_blank");
    } else {
      window.open(`${prependToAPI}api/admin/core/insuranceaudit/import/?contract_ref=${contract.contract_ref}`, "_blank");
    }

  }

  const handleGoToInsuranceAuditExport = () => {
    exportInsuranceAudits({ contract, portfolio, headers: includeCSRFHeader(csrfToken, { 'content-type': 'application/x-www-form-urlencoded' }) });
  }

  return (
    <InlineWrapper>
      <Paper elevation={3}>
        <PrintHeading />
        <GridHeader
          title={`Audit`}
          className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
          subTitle={getSubTitle(metadata)}
        />
        <Header
          title={`${subTitleIndex} ${allAuditsTitle}`}
          className="no-screen"
        />
        <div className="dataActionButtonsWrapper">
          {metadata.privileges.POST && <Button
            onClick={handleGoToInsuranceAuditImport}
            className="no-print"
            color="primary">Import Audits</Button>
          }
          {metadata.privileges.POST && <Button
            onClick={handleGoToInsuranceAuditExport}
            className="no-print"
            color="primary">Export Audits</Button>
          }
        </div>
        {dataSource && (
          <DataGrid
            className='no-print'
            dataSource={dataSource}
            onRowInserting={handleRowInserting}
            onEditingStart={handleEditingStart}
            onRowUpdating={handleRowUpdating}
            onRowUpdated={handleRowUpdated}
            {...getGridProps(metadata.activeMeta)}
            onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
            onContentReady={() => setContentReady(true)}
            showColumnHeaders
          >
            <Paging enabled={false} />
            <Editing
              mode="cell"
              allowUpdating={metadata.privileges.PUT}
              allowDeleting={metadata.privileges.DELETE}
              allowAdding={metadata.privileges.POST}
            />

            {/* <Column {...getColumnPropsExt('asset_description')}>
              <RequiredRule />
            </Column> */}
            {portfolio && renderColIfMetaDataDefined('contract', true, { caption: 'Contract' })}
            {renderColIfMetaDataDefined('asset_description', true)}
            {renderColIfMetaDataDefined('report_inspection_date', true, { width: 125 })}
            {renderColIfMetaDataDefined('examiner_comment_summary', true)}
            {renderColIfMetaDataDefined('jbs_comments', true)}
            {renderColIfMetaDataDefined('responsibility', false, { width: 125 })}
            {renderColIfMetaDataDefined('date_completed', false, { width: 125 })}
            {renderColIfMetaDataDefined('next_inspection_date', false, { width: 125 })}
            {/* <Column {...getColumnPropsExt('action_required_by')} width={125} /> */}
          </DataGrid>
        )}
        <PrintDataGrid
          meta={metadata.activeMeta}
          visibleColumns={portfolio ? portfolioAuditColumns : auditColumns}
          records={dataSource?.items()}
        />
      </Paper>
    </InlineWrapper>
  );
};

export default withPageContext(Audit, {
  visibilityMenu: auditRightVisibilityMenu,
});

export const PortfolioAudit = withPortfolioPageContext(Audit, {
  visibilityMenu: auditRightVisibilityMenu
})
